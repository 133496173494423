import { toast } from "react-toastify";
import { changeRoute } from "../../utils/core";
import * as api from "../../services/api";
import * as vimeo from "../../services/vimeo";
import {
  ADD_TUTORIAL,
  GET_ALL_TUTORIAL,
  GET_TUTORIAL_DETAILS,
  MY_UPLOADS_TUTORIAL,
  LIKE_TUTORIAL,
  DISLIKE_TUTORIAL,
  GET_TYPES,
} from "../constants/tutorialActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";
import { MY_ASSOCIATION_TUTORIAL } from "../constants/assocGroupActionTypes";

export const getTutorialTypes = (data) => async (dispatch) => {
  try {
    console.log("hELLO tUTORIAL")
    const result = await api.getTutorialTypes(data);
    console.log("tutoprial type from api", result)
    dispatch({ type: GET_TYPES, payload: result.data.data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllTutorials =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllTutorials(data);
      // if(result.data.data.docs.length > 0){
      //   dispatch({ type: GET_ALL_TUTORIAL, payload: result.data.data.docs , hasMore : true, reset:reset });
      // }else {
      //   dispatch({ type: GET_ALL_TUTORIAL, payload: [] , hasMore : false, reset:reset });
      // }
      dispatch({
        type: GET_ALL_TUTORIAL,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });

    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error.message);
    }
  };

export const getTutorialDetails = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.getTutorialDetails(data);
    dispatch({ type: CLEAR_LOADING });

    dispatch({ type: GET_TUTORIAL_DETAILS, payload: result.data.data[0] });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const getMyUploads = (data) => async (dispatch) => {
  try {
    const result = await api.getMyUploadTutorial(data);

    dispatch({ type: MY_UPLOADS_TUTORIAL, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });

  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });

  }
};

export const doDelete = (data, isAssoc = false) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const result = await api.deleteTutorial(data);
    if (result.data.status.success) {
      toast.success("Tutorial Deleted Successfully...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if(isAssoc) {
        const result = await api.myAssociationTutorial({});
        dispatch({ type: MY_ASSOCIATION_TUTORIAL, payload: result.data.data });
      } else {
        const response = await api.getMyUploadTutorial({});
        dispatch({ type: MY_UPLOADS_TUTORIAL, payload: response.data.data });
      }

    } else {
      toast.error("Could not delete this Tutorial, try after sometime...!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const addLikeTutorial = (data) => async (dispatch) => {
  try {
    const result = await api.addLikeTutorial(data);

    dispatch({ type: LIKE_TUTORIAL, payload: result.data.status });
  } catch (error) {
    console.log(error.message);
  }
};

export const disLikeTutorial = (data) => async (dispatch) => {
  try {
    const result = await api.disLikeTutorial(data);

    dispatch({ type: DISLIKE_TUTORIAL, payload: result.data.status });
  } catch (error) {
    console.log(error.message);
  }
};

export const addViewsTutorial = (data) => async (dispatch) => {
  try {
    const result = await api.addViewsTutorial(data);
  } catch (error) {
    console.log(error.message);
  }
};

export const postCommentTutorial = (data) => async (dispatch) => {
  try {
    const result = await api.postCommentTutorial(data);

    if (result.data.status.success) {
      toast.success("Your comment has been posted...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const response = await api.getTutorialDetails(data);
      dispatch({ type: GET_TUTORIAL_DETAILS, payload: response.data.data[0] });
    } else {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    console.log("Comment result");
    console.log(result);
  } catch (error) {
    console.log(error.message);
  }
};

export const uploadTutorial = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const fileType = data.get("fileType");
    if (fileType == "VIDEO") {
      const file = data.get("file");
      console.log(file);
      data.delete("file");
      const vimeoUrl = await vimeo.UploadVideo(file,dispatch);
      console.log("vimeo", vimeoUrl);
      let url = vimeoUrl.split("/");
      data.append("url", url[2]);
    }

    let result = {};
    if (data.get("assocId")) {
      result = await api.postAssociationTutorial(data);
    } else {
      result = await api.uploadTutorial(data);
    }

    if (result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });

      dispatch({ type: ADD_TUTORIAL, payload: data });
      if (data.get("assocId")) {
        let assId = data.get("assocId")
        navigate(`/myAssociationTutorials/${assId}`)
      }else{
        navigate(`/myTutorials`)
      } 
      dispatch({ type: CLEAR_LOADING });

      toast.success(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
        toast.error(result.data.status.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const updateTutorial = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const fileType = data.get("fileType");
    if (fileType && fileType == "VIDEO") {
      const file = data.get("file");
      console.log(file);
      data.delete("file");
      const vimeoUrl = await vimeo.UploadVideo(file,dispatch);
      console.log("vimeo", vimeoUrl);
      let url = vimeoUrl.split("/");
      data.append("url", url[2]);
    }

    const result = await api.updateTutorial(data);
    if (result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });

      toast.success("Tutorial uploaded successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: ADD_TUTORIAL, payload: data });
      if (data.get("assocId")) {
        let assId = data.get("assocId")
        navigate(`/myAssociationTutorials/${assId}`)
      }else{
        navigate(`/myTutorials`)
      } 
    } else {
      dispatch({ type: CLEAR_LOADING });

      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
