import { toast } from 'react-toastify';
import * as api from '../../services/api';
import { SET_USER_PROFILE_STATUS } from '../constants/globalActionTypes';

export const isProfileComplete = () => async (dispatch) => {
  try {
    const percentage = localStorage.getItem("profileComplete");
    let profileCompletionStatus = percentage > 90 ? true : false;
    dispatch({
      type: SET_USER_PROFILE_STATUS,
      payload: profileCompletionStatus,
    });
  } catch (error) {
    console.log(error.message);
  }
};