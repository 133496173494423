import React, {useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../img/logo.svg";
import authRoutes from "../../../routes/authRouter";

const CompleteProfilePopUp = ({ show }) => {
  let navigate = useNavigate();
  const location = useLocation();
  let shouldShowPopup = authRoutes.every((route) =>
    location.pathname !== route.path
  );

  shouldShowPopup  = location.pathname === "/createPatientProfile" ? false : shouldShowPopup

  const [isHide, setIsHide] = useState(false);
  const [isShow, setIsShow] = useState(show && shouldShowPopup);

  const closeModal = () => {
    setIsShow(false)
  };

  
  return (
    <Modal
      size="lg"
      show={isShow}
      onHide={isHide}
      backdrop="static"
      keyboard={false}
      animation={false} 
    >
      <Modal.Header className="complete-profile-modal-header">
        <img src={logo} alt="Logo" className="modal-logo" />
        {/* <Modal.Title className="text-light h6">Your Medical Journey Begins Here!</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        Complete your profile for participation in Surgic Share.
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
        className="complete-profile-button"
          variant="primary"
          onClick={() => navigate("/createPatientProfile")}
        >
          Complete Now
        </Button>
        <Button
        className="complete-profile-button"
          variant="danger"
          onClick={() => closeModal()}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompleteProfilePopUp;
