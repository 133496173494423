import React from "react";
import { useNavigate } from "react-router-dom";

const PublicFooter = () => {
  let navigate = useNavigate();
  return (
    <footer class="footer-wrap py-4 py-md-5 bg-dark">
      <div class="container">
        <div class="row clearfix">
          <div class="col-md-6 col-lg-3 footer-box-wrap">
            <img
              src="https://surgicshare.com/img/footer-logo.584f6c79.svg"
              width="140"
              class="img-fluid"
            />
            <p class="mt-4">
              {" "}
              Surgicshare is a common digital platform for expert, young and
              future surgeons to learn and share best surgical practice.{" "}
            </p>
            <ul class="footer-social list-unstyled mt-4 mb-0">
              <li>
                <a
                  href="https://facebook.com/surgicshare"
                  target="_blank"
                  title="Like on Facebook"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/SurgicShare"
                  target="_blank"
                  title="Follow on Twitter"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/surgicshare"
                  target="_blank"
                  title="Follow on Instagram"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/surgic-share/"
                  target="_blank"
                  title="Follow on LinkedIn"
                >
                  <i class="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-3 footer-box-wrap">
            <h6 class="mt-4">Quick Links</h6>
            <div class="row clearfix mt-0 mt-sm-4">
              <div class="col-6">
                <ul class="list-unstyled">
                  <li>
                    <a onClick={()=>navigate("/about")} href="javascript:void(0)" class="" title="About">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a onClick={()=>navigate("/speciality")} href="javascript:void(0)" class="" title="Specialities">
                      Specialities
                    </a>
                  </li>
                  <li>
                    <a onClick={()=>navigate("/services")} href="javascript:void(0)" class="" title="Services">
                      Services
                    </a>
                  </li>
                  <li>
                    <a onClick={()=>navigate("/gallery")} href="javascript:void(0)" class="" title="Contact">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a onClick={()=>navigate("/contact")} href="javascript:void(0)" class="" title="Contact">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 footer-box-wrap">
            <h6 class=" mt-4">Legal</h6>
            <ul class="list-unstyled mt-0 mt-sm-4">
              <li>
                <a href="javascript:void(0)" class="" title="Terms of Service">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="" title="Privacy Policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="" title="Pricing Policy">
                  Pricing Policy
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  class=""
                  title="Cancellation Policy"
                >
                  Cancellation Policy
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-3 footer-box-wrap footer-contact-box-wrap">
            <h6 class="mt-4">Contact Us</h6>
            <p class="mt-0 mt-sm-4">
              <a href="tel:919971721564" title="Call Surgic Share">
                {" "}
                +91 99717 21564
              </a>
            </p>
            <p>
              <a href="mailto:surgicshare@gmail.com" title="Email Surgic Share">
                {" "}
                surgicshare@gmail.com
              </a>
              <a href="mailto:admin@surgicshare.com" title="Email Surgic Share">
                {" "}
                admin@surgicshare.com
              </a>
            </p>
            <p>
              <span class="d-inline-block">
                D. No- 4-302, Shivalli Village,
                <br /> Laxmindra Nagar, 5th Cross,
                <br /> Udupi 576104, Karnataka, India
              </span>
            </p>
          </div>
        </div>
        <hr class="mb-4" />
        <div class="row">
          <div class="col-12 col-lg-6 text-center text-lg-start order-2 order-lg-1">
            <p class="mb-0 text-light">
              <small>
                Copyright © {new Date().getFullYear()} Surgicshare Private
                Limited. All rights reserved.
              </small>
            </p>
          </div>
          <div class="col-12 col-lg-6 text-center text-lg-end mb-3 mb-lg-0 order-1 order-lg-2">
            <ul class="list-unstyled list-inline footer-socials-list mb-0">
              <li class="list-inline-item">
                <a
                  href="https://facebook.com/surgicshare"
                  target="_blank"
                  title="Facebook"
                  class="text-light"
                >
                  <i class="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://instagram.com/surgicshare"
                  target="_blank"
                  title="Instagram"
                  class="text-light"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://www.linkedin.com/company/surgic-share/"
                  target="_blank"
                  title="Linkedin"
                  class="text-light"
                >
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://twitter.com/SurgicShare"
                  target="_blank"
                  title="Twitter"
                  class="text-light"
                >
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PublicFooter;
