import { toast } from "react-toastify";
import { changeRoute } from "../../utils/core";
import * as api from "../../services/api";
import * as vimeo from "../../services/vimeo";
import {
  ADD_VIDEO,
  GET_ALL_VIDEOS,
  GET_VIDEO_DETAILS,
  MY_UPLOADS,
  LIKE,
  DISLIKE,
  EDIT_VIDEO,
} from "../constants/videoActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";
import { MY_ASSOCIATION_VIDEO } from "../constants/assocGroupActionTypes"

export const getAllVideos = (data, reset = false) => async (dispatch) => {
    try {
      const result = await api.getAllVideos(data);
      
      dispatch({
        type: GET_ALL_VIDEOS,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
      console.log(error.message);
    }
  };

export const getVideoDetails = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.getVideoDetails(data);

    dispatch({ type: GET_VIDEO_DETAILS, payload: result.data.data[0] });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const getMyUploads = (data) => async (dispatch) => {
  try {
    const result = await api.getMyUploads(data);

    dispatch({ type: MY_UPLOADS, payload: result.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const doDelete = (data, assoc = false) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const result = await api.deleteVideo(data);
    if (result.data.status.success) {
      toast.success("Video Deleted Successfully...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if(assoc) {
        const result = await api.myAssociationVideos({});
        dispatch({ type: MY_ASSOCIATION_VIDEO, payload: result.data.data });
      } else {
        const response = await api.getMyUploads({});
        dispatch({ type: MY_UPLOADS, payload: response.data.data });
      }

    } else {
      toast.error("Could not delete this video, try after sometime...!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const addLike = (data) => async (dispatch) => {
  try {
    const result = await api.addLike(data);

    dispatch({ type: LIKE, payload: result.data.status });
  } catch (error) {
    console.log(error.message);
  }
};

export const unLike = (data) => async (dispatch) => {
  try {
    const result = await api.disLike(data);

    dispatch({ type: DISLIKE, payload: result.data.status });
  } catch (error) {
    console.log(error.message);
  }
};

export const addViews = (data) => async (dispatch) => {
  try {
    const result = await api.addViews(data);
  } catch (error) {
    console.log(error.message);
  }
};

export const postComment = (data) => async (dispatch) => {
  try {
    const result = await api.postComment(data);

    if (result.data.status.success) {
      toast.success("Your comment has been posted...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const response = await api.getVideoDetails(data);
      dispatch({ type: GET_VIDEO_DETAILS, payload: response.data.data[0] });
    } else {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    console.log("Comment result");
    console.log(result);
  } catch (error) {
    console.log(error.message);
  }
};

export const uploadVideo = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const file = data.get("attachmentFile");
    let url = "";

    if (data.get("urlType") === "video") {
      data.delete("attachmentFile");
      const vimeoUrl = await vimeo.UploadVideo(file, dispatch);
      url = vimeoUrl.split("/");
      data.append("url", url[2]);
    } else {
      data.delete("attachmentFile");
      url = data.get("attachmentUrl");
      data.append("url", url);
    }

    let result = {};
    if (data.get("assocId")) {
      result = await api.postAssociationSurgeryVideo(data);
    } else {
      result = await api.uploadVideo(data);
    }

    if (result.data.status.success) {
      toast.success("Video uploaded successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: ADD_VIDEO, payload: data });
      dispatch({ type: CLEAR_LOADING });
      if (data.get("assocId")) {
        let assId = data.get("assocId");
        navigate(`/myAssociationSurgeryVideos/${assId}`);
      } else {
        navigate(`/mySurgeryVideos`);
      }
      return "success";
    } else {
      dispatch({ type: CLEAR_LOADING });
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return "error";
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "error";
  }
};

export const editVideo = (data, navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    let url = "";
    const file = data.get("attachmentFile");
    if (data.get("urlType") === "video") {
      if (file && file !== "null") {
        data.delete("attachmentFile");
        const vimeoUrl = await vimeo.UploadVideo(file, dispatch);
        url = vimeoUrl.split("/");
        data.append("url", url[2]);
      }
    } else {
      data.delete("attachmentFile");
      url = data.get("attachmentUrl");
      data.append("url", url);
    }
 
    const result = await api.editVideo(data);
    if (result.data.status.success) {
      dispatch({ type: CLEAR_LOADING });
      toast.success("Video updated successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (data.get("assocId")) {
        let assId = data.get("assocId");
        navigate(`/myAssociationSurgeryVideos/${assId}`);
      } else {
        navigate(`/mySurgeryVideos`);
      }
      return "success";
    } else {
      dispatch({ type: CLEAR_LOADING });
      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return "error";
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "error";
  }
};
