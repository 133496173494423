import {
  GET_PATIENT_REFFERAL_RECEIVED,
  GET_PATIENT_REFFERED,
  GET_SENIOR_SURGEON_DETAILS,
  GET_SURGEON_PROFILE,
  GET_SURGEON_PROFILE_INFO,
  MAKE_PAYMENT,
  UPDATE_PATIENT_SCHEDULE,
  UPDATE_PUBLIC_PROFILE,
} from "../constants/patientReferralActionTypes";
import * as api from "../../services/api";
import { toast } from "react-toastify";
import { doPayment } from "../../utils/payment";
import * as payment from "../../services/razorpay-profile";
import {
  CLEAR_LOADING,
  SET_LOADING,
  SET_USER_PROFILE_STATUS,
} from "../constants/globalActionTypes";

export const getAllSeniorSurgeons =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllSeniorSurgeons(data);
      dispatch({
        type: GET_SENIOR_SURGEON_DETAILS,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

export const getProfileInfo = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.patientReferralProfile(data);

    dispatch({
      type: GET_SURGEON_PROFILE_INFO,
      payload: result.data.data,
    });
    dispatch({ type: CLEAR_LOADING });

    return result.data.data;
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const makePayment = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.patientReferralMakePayment(data);
    
    if(result.data.status.success) {
      dispatch({
        type: MAKE_PAYMENT,
        payload: result.data.data,
      });
      dispatch({ type: CLEAR_LOADING });
  
      let request = result.data.data;
      request.type = "REFERRAL";
      await payment.makePayment(request, dispatch);
    } else {
        toast.error(result.data.status.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const updateSchedule = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.patientReferralUpdateSchedule(data);

    const respone = await api.patientReferralProfile(data);

    dispatch({
      type: GET_SURGEON_PROFILE_INFO,
      payload: respone.data.data,
    });

    dispatch({
      type: UPDATE_PATIENT_SCHEDULE,
      payload: result.data.data,
    });
    dispatch({ type: CLEAR_LOADING });

    toast.success(result.data.status.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const updatePublicProfile =
  (formdata, publicProfileFormData) => async (dispatch) => {
    dispatch({ type: SET_LOADING });

    try {
      const result = await api.updatePublicProfile(formdata);

      if(result.data.status.success) {
        dispatch({
          type: UPDATE_PUBLIC_PROFILE,
          payload: result.data.data,
        });
        let useData = JSON.parse(localStorage.getItem("userData"));
        let newData = {
          ...useData,
          ...result.data.data,
        };
        localStorage.setItem("userData", JSON.stringify(newData));
        localStorage.setItem("userId", newData._id);
        let profileCompletionStatus =
          result.data.data.profilePercentage > 90 ? true : false;
        dispatch({
          type: SET_USER_PROFILE_STATUS,
          payload: profileCompletionStatus,
        });
        dispatch({ type: CLEAR_LOADING });

        toast.success("User Profile Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {


        dispatch({ type: CLEAR_LOADING });

        toast.error(result.data.status.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }

      
    } catch (error) {
      
      dispatch({ type: CLEAR_LOADING });

      toast.error("Please fill all the Fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
export const updatePersonalProfile =
  (formdata, publicProfileFormData) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      const result = await api.updatePersonalProfile(formdata);
      dispatch({
        type: UPDATE_PUBLIC_PROFILE,
        payload: result.data.data,
      });
      let useData = JSON.parse(localStorage.getItem("userData"));
      let newData = {
        ...useData,
        ...result.data.data,
      };
      localStorage.setItem("userData", JSON.stringify(newData));
      localStorage.setItem("userId", newData._id);
      dispatch({ type: CLEAR_LOADING });

      toast.success("User Profile Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

export const getSurgeonLocation = (data) => async () => {
  try {
    const result = await api.getSurgeonsLocation(data);
    return result.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const referPatient = (data) => async () => {
  try {
    const result = await api.createPatientInfo(data);
    toast.success(result.data.status.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "success";
  } catch (error) {
    toast.error("Failed to save data.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const getSurgeonProfile = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.getSurgeonsProfile(data);
    dispatch({
      type: GET_SURGEON_PROFILE,
      payload: result?.data?.data[0],
    });
    dispatch({ type: CLEAR_LOADING });

    return result.data.data;
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error.message);
  }
};

export const getPatientReffered =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getPatientReffered(data);
      dispatch({
        type: GET_PATIENT_REFFERED,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
    }
  };

export const getPatientRefferralReceived =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getPatientRefferalReceived(data);
      dispatch({
        type: GET_PATIENT_REFFERAL_RECEIVED,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });
    }
  };
